import React from 'react'
import Layout from '../components/Layout/'

import * as S from '../components/GlobalStyles/styles'

const Contato = () => (
  <Layout>
    <S.Contact>
      <S.TitleWrapper>
        <S.Title>Fale com a gente</S.Title>
      </S.TitleWrapper>
      <S.Text align="center">
        Mande uma mensagem preenchendo o formulário abaixo.
      </S.Text>
      <form
        id="contactform"
        action="https://formsubmit.io/send/colegioexato.edu@gmail.com"
        method="POST"
      >
        <input name="_redirect" type="hidden" id="name" value="/" />
        <input name="nome" type="text" id="nome" placeholder="Nome" />
        <input
          name="telefone"
          type="tel"
          id="telefone"
          placeholder="Telefone"
        />
        <input name="email" type="email" id="email" placeholder="E-mail" />
        <textarea
          name="comment"
          id="comment"
          rows="3"
          placeholder="Mensagem"
        ></textarea>

        <input name="_formsubmit_id" type="text" style={{ display: 'none' }} />
      </form>
      <form>
        <input value="Enviar" type="submit" />
      </form>
    </S.Contact>
  </Layout>
)

export default Contato
